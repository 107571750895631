import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useGeneralInvite from "database/events/useGeneralInvite";
import styled from "styled-components/macro";
import LabelledTextInput from "components/shared/LabelledTextInput";
import { Button, MiniButton } from "components/shared/standardElements";
import { useTranslation } from "react-i18next";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import Loading from "components/shared/Loading";
import InactiveError from "./InactiveError";
import ParticipantLimitError from "./ParticipantLimitError";
import PastDeadlineError from "./PastDeadlineError";
import ContactInfo from "./ContactInfo";
import Modal from "components/shared/modal/Modal";
import { useForm } from "react-hook-form";
import UserNoticeModal from "components/shared/UserNoticeModal";
import { useLoading, ThreeDots } from "@agney/react-loading";
import { toast } from "react-toastify";

const GeneralInvite = () => {
  const { search } = useLocation();
  const { t, i18n } = useTranslation();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    loaderProps: {
      // Any props here would be spread on to the indicator element.
      style: { color: "black" }
    },
    indicator: <ThreeDots width='40px' />
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues
  } = useForm();

  const watchEmail = watch("email");

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [courseID, setCourseID] = useState();
  const [eventID, setEventID] = useState();
  const [shoppingLink, setShoppingLink] = useState();
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const [submissionError, setSubmissionError] = useState();

  const {
    courseInfo,
    eventInfo,
    error,
    okToProceed,
    addParticipant,
    customMessage
  } = useGeneralInvite(eventID);

  useEffect(() => {
    let parsedParams = {};
    const urlParams = new URLSearchParams(search);
    urlParams.forEach((value, key) => (parsedParams[key] = value));
    // setCourseID(parsedParams.cid);
    //The split below is to address issues where a malformed URL is submitted with a space... usually from email formatting issues
    setEventID(parsedParams.eid?.split(" ")[0]);
    // console.log(parsedParams);
  }, [search]);

  const submitForm = async (data) => {
    setIsSubmitting(true);
    const submissionResult = await addParticipant(data.name, data.email);

    if (submissionResult.data.success) {
      setSubmissionComplete(true);
    } else {
      setSubmissionError(submissionResult.data.error);
    }

    console.log(submissionResult);
    setShoppingLink(submissionResult.data.shoppingLink);

    setIsSubmitting(false);
  };

  const onChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  if (
    error == null &&
    Object.keys(courseInfo).length === 0 &&
    Object.keys(eventInfo).length === 0
  ) {
    return <Loading isLoading={true} />;
  }

  if (error === "existence" || submissionError === "existence") {
    return <InactiveError />;
  }

  if (error === "pastDeadline" || submissionError === "pastDeadline") {
    return <PastDeadlineError courseInfo={courseInfo} eventInfo={eventInfo} />;
  }

  return (
    <Page>
      <HeaderAndInputs>
        <Image src={courseInfo.logoURL || "/icon-adidas-logo.svg"} />
        <CourseName>{courseInfo.name}</CourseName>
        <EventName>{eventInfo.eventName}</EventName>
        {/* HACK for US regionalization to not show language picker */}
        {(window.location.hostname.includes(".ca") ||
          window.location.hostname.includes("localhost")) && (
          <LanguagePicker>
            <Language
              selected={i18n.language === "en"}
              onClick={() => onChangeLanguage("en")}
            >
              EN
            </Language>
            <Language
              selected={i18n.language === "fr-CA"}
              onClick={() => onChangeLanguage("fr-CA")}
            >
              FR
            </Language>
          </LanguagePicker>
        )}
        {!submissionComplete ? (
          <CenterWrapper>
            <Message>
              {i18n.language === "fr-CA" && customMessage.frMessage ? (
                customMessage.frMessage.replace(/<br>/g, "\n")
              ) : i18n.language === "en" && customMessage.enMessage ? (
                customMessage.enMessage.replace(/<br>/g, "\n")
              ) : (
                <>
                  <p>{t("sign_up_message_1")}</p>
                  <p>{t("sign_up_message_2")}</p>
                </>
              )}
            </Message>
            <InputsWrapper>
              <form onSubmit={handleSubmit(submitForm)}>
                <TextInput
                  error={errors.name}
                  marginBottom='10px'
                  type='text'
                  labelText={t("name_title")}
                  id='name'
                  {...register("name", { required: true })}
                />
                <TextInput
                  error={errors.email}
                  marginBottom='10px'
                  type='email'
                  labelText={t("email_address")}
                  id='email'
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("invalid_email_error")
                    }
                  })}
                />
                <TextInput
                  error={errors.confirmEmail}
                  marginBottom='10px'
                  type='email'
                  labelText={t("confirm_email_label")}
                  id='confirmEmail'
                  {...register("confirmEmail", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("invalid_email_error")
                    },
                    validate: (value) => {
                      return value === watchEmail;
                    }
                  })}
                />
                <StyledButton
                  disabled={
                    error != null ||
                    (error == null && !okToProceed) ||
                    isSubmitting
                  }
                  type='submit'
                >
                  {(error == null && !okToProceed) || isSubmitting ? (
                    <div {...containerProps}>{indicatorEl}</div>
                  ) : (
                    t("submit_button")
                  )}
                </StyledButton>
              </form>
            </InputsWrapper>
          </CenterWrapper>
        ) : (
          <CenterWrapper>
            <Message>
              <p>{t("register_thank_you_1")}</p>
              {shoppingLink ? (
                <p>{t("register_thank_you_2a")}</p>
              ) : (
                // <p>{`Your personal shopping link was sent to: ${getValues(
                //   "email"
                // )}`}</p>
                <p>
                  {t("register_thank_you_2b", {
                    email: getValues("email")
                  })}
                </p>
              )}
            </Message>
            {shoppingLink && (
              <>
                <LinkWrapper>
                  {/* <MessageLink href={shoppingLink} target='_blank'>
                {shoppingLink}
              </MessageLink> */}
                  <MiniButton
                    onClick={() => window.open(shoppingLink, "_blank")}
                  >
                    {t("start_shopping")}
                  </MiniButton>
                  <MiniButton
                    onClick={() => {
                      toast.success(t("invite_copied_notification"), {
                        autoClose: 2000,
                        hideProgressBar: true
                      });
                      navigator.clipboard.writeText(shoppingLink);
                    }}
                  >
                    {t("copy_link_button")}
                  </MiniButton>
                </LinkWrapper>
                <Message>
                  <p>
                    {t("register_thank_you_2c", {
                      email: getValues("email")
                    })}
                  </p>
                </Message>
              </>
            )}
          </CenterWrapper>
        )}
      </HeaderAndInputs>
      {eventInfo.hideContactInfo ? (
        <NoContactSpacer />
      ) : (
        <ContactInfo
          contactInfo={
            eventInfo.customContactInfo || {
              email: courseInfo.email,
              phone: courseInfo.phone
            }
          }
        />
      )}
      <Modal
        show={error === "inactive" || submissionError === "inactive"}
        disableCloseOnOutsideClick
        closeButtonHidden
      >
        <InactiveError inModal />
      </Modal>
      <Modal
        show={error === "limit" || submissionError === "limit"}
        disableCloseOnOutsideClick
        closeButtonHidden
      >
        <ParticipantLimitError
          hideContactInfo={eventInfo.hideContactInfo}
          customContactInfo={eventInfo.customContactInfo}
          courseInfo={courseInfo}
          eventInfo={eventInfo}
        />
      </Modal>
      <UserNoticeModal
        show={submissionError === "missingInfo"}
        onClose={() => setSubmissionError()}
        title={"Missing Info"}
        disableCloseOnOutsideClick
        closeButtonHidden
        message={t("missing_name_email_error")}
      />
      <UserNoticeModal
        show={submissionError === "userExists"}
        onClose={() => setSubmissionError()}
        title={"Participant Exists"}
        disableCloseOnOutsideClick
        closeButtonHidden
        message={
          <>
            <UserExistsMessage>
              {t("sign_up_user_exists_1")}
              <span>{watchEmail}</span>
            </UserExistsMessage>
            <UserExistsMessage>{t("sign_up_user_exists_2")}</UserExistsMessage>
          </>
        }
      />
      <UserNoticeModal
        show={submissionError === "processingError"}
        onClose={() => setSubmissionError()}
        title={"Database Error"}
        disableCloseOnOutsideClick
        closeButtonHidden
        message={
          <>
            <p>{t("sign_up_db_error_1")}</p>
            <p>{t("sign_up_db_error_2")}</p>
            <p>{t("sign_up_db_error_3")}</p>
          </>
        }
      />
      <Loading isLoading={isSubmitting} />
    </Page>
  );
};

const Page = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 3vh;
  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: center;
  max-width: 95%;
  padding-bottom: 30px;
  overflow-y: auto;
`;

const HeaderAndInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
`;

const Image = styled.img`
  width: 150px;
  margin-bottom: 10px;

  @media screen and (max-width: 550px) {
    width: 100px;
  }
`;

const CourseName = styled.div`
  padding-top: 10px;
  text-transform: uppercase;
  font-size: ${FONTSIZE.medium};
  color: ${COLORS.text};
`;

const EventName = styled.div`
  text-transform: uppercase;
  font-size: ${FONTSIZE.large};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
`;

const CenterWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 40px;
  /* justify-content: center; */
`;

const Message = styled.div`
  font-size: ${FONTSIZE.medium};
  color: ${COLORS.text};

  /* text-align: center; */
  max-width: 500px;
  white-space: pre-wrap;
  text-align: center;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
`;

const MessageLink = styled.a`
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  text-align: center;
`;

const InputsWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TextInput = styled(LabelledTextInput)`
  min-width: 300px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  min-width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.4s;
`;

const LanguagePicker = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-around;
  color: ${COLORS.text};
  width: 100px;
  cursor: pointer;
`;

const Language = styled.div`
  text-decoration: ${({ selected }) => selected && "underline"};

  :hover {
    text-decoration: underline;
  }
`;

const UserExistsMessage = styled.p`
  span {
    font-weight: ${FONTWEIGHT.heavy};
    padding-left: 5px;
  }
`;

const NoContactSpacer = styled.div`
  height: 80px;
`;

export default GeneralInvite;
