import React from "react";
import styled from "styled-components/macro";
import { COLORS, FONTWEIGHT } from "helpers/designConstants";
import SectionArticleCard from "./SectionArticleCard";
import useArticleZoomModal from "components/shared/articleZoomModal/useArticleZoomModal";
import ArticleZoomModal from "components/shared/articleZoomModal/ArticleZoomModal";

const ProductSection = ({
  sectionData,
  articleData,
  hideMSRP,
  allEventArticles,
  onSelectArticle,
  userSelections
}) => {
  const {
    toggleArticleZoomModal,
    isZoomModalShowing,
    onZoomArticle,
    zoomArticle,
    zoomArticleImages
  } = useArticleZoomModal();

  return (
    <Container>
      <Header>
        <SectionName>{sectionData.name}</SectionName>
      </Header>
      <SectionBody>
        {sectionData.articles.map((articleID) => (
          <SectionArticleCard
            key={articleID}
            article={articleData?.[articleID]}
            hideMSRP={hideMSRP}
            customPrice={allEventArticles?.[articleID].customPrice}
            onZoomArticle={onZoomArticle}
            isSelected={userSelections[articleID] != null}
            didSelectAddRemove={onSelectArticle}
          />
        ))}
      </SectionBody>
      <ArticleZoomModal
        show={isZoomModalShowing}
        onClose={toggleArticleZoomModal}
        imageList={zoomArticleImages}
        article={zoomArticle}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 2px solid ${COLORS.secondary};
  border-bottom: 2px solid ${COLORS.secondary};
  background: ${COLORS.pageBackground};
`;

const SectionName = styled.div`
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionBody = styled.div`
  background: ${COLORS.pageBackground};
  display: grid;
  grid-gap: 5px;
  padding: 5px 5px 15px;
  margin-bottom: 15px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 0.3333fr));

  @media screen and (max-width: 732px) {
    grid-template-columns: 1fr;
  }
  align-content: start;
  border-bottom: 1px solid ${COLORS.secondary};
`;

export default ProductSection;
